<template>
  <div class="container">
    <div class="guidance c252">
      <p class="font14 fwb title">How to get started</p>
      <p class="font13">1. Open a part or create a new part in SolidWorks</p>
      <p class="font13">2. Click ADD PART TO QUOTE to upload your part</p>
      <p class="font13">3. Get an instant quote!</p>
    </div>
    <el-button type="primary" :loading="loading" style="width: 80%;" @click="addPart"
      >ADD PART TO QUOTE</el-button
    >
    <div class="mgt20 c888 font13">Learn about <a class="cursor-hand underline c444" @click="linkUrl('https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=b3a6f069-cb0c-4459-8804-12ebc8194387&env=na4&acct=0cc07454-3642-4f7f-a82a-020161d814bb&v=2&_ga=2.73998738.160161867.1704651358-461306255.1702382693')">RapidDirect's NDA Agreement</a> or <a class="cursor-hand underline c444" @click="linkUrl('mailto:support@rapiddirect.com')">Contact us</a></div>
  </div>
</template>

<script>
import { createQuote } from './common'
export default {
  data(){
    return {
      loading:false
    }
  },
  mounted() {
    const quoteNo = sessionStorage.getItem('quote_no')
    const techgroup = sessionStorage.getItem('techgroup')

    if (quoteNo) {
      this.$router.replace({
        path: '/quote',
        query: {
          quote_no: quoteNo,
          type: 1,
          techgroup
        }
      })
    }
  },
  created() {},
  methods: {
    linkUrl(url) {
      window.chrome.webview.hostObjects.bridge.openUrl(url)
    },
    async addPart() {
      this.loading = true
      await createQuote(this).catch(()=>{})
      this.loading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .guidance{
    width: 80%;
    line-height: 22px;
    p{
      width: 100%;
      text-align: left;
      color: #444;
      &:last-of-type{
        margin-bottom: 30px;
      }
    }
    .title{
      margin-bottom: 20px;
      color: #252525;
    }
  }
}
</style>
