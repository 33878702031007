import dayjs from "dayjs";
export const formatPrice = price => {
  if (!price) return
  // 千分位分隔符
  return Number(price).toLocaleString()
}

// 根据文件后缀获取类型
export const getFileType = suffix => {
  let type = ''
  if (['step', 'stp', 'stl'].includes(suffix)) {
    type = 1
  } else if (suffix === 'pdf') {
    type = 2
  } else if (['xls', 'xlsx', 'csv'].includes(suffix)) {
    type = 3
  } else if (['doc', 'docx'].includes(suffix)) {
    type = 4
  } else if (['ppt', 'pptx'].includes(suffix)) {
    type = 5
  } else if (
    ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].includes(
      suffix
    )
  ) {
    type = 6
  } else {
    type = 7
  }
  return type
}

// 比较时间戳相距时间
export function diffTime(startTime = +new Date(), endTime = +new Date()) {
  if (startTime >= endTime) {
    return {
      day: '00',
      hour: '00',
      minute: '00',
      second: '00',
      ms: '00',
      total_hour: '00',
      stopFlag: true
    }
  }

  const time = endTime - startTime

  // 毫秒数
  const s = 1000
  const m = s * 60
  const h = m * 60
  const d = h * 24

  let day = Math.floor(time / d)
  let hour = Math.floor((time % d) / h)
  let minute = Math.floor((time % h) / m)
  let second = Math.floor((time % m) / s)
  let ms = Math.floor((time % 1000) / 10)
  let totalHour = day <= 0 ? hour : day * 24 + hour

  if (day < 10) {
    day = '0' + day
  }
  if (hour < 10) {
    hour = '0' + hour
  }
  if (minute < 10) {
    minute = '0' + minute
  }
  if (second < 10) {
    second = '0' + second
  }
  if (ms < 10) {
    ms = '0' + ms
  }
  if (totalHour < 10) {
    totalHour = '0' + totalHour
  }

  return {
    day,
    hour,
    minute,
    second,
    ms,
    totalHour
  }
}
// export const px2rem = px => {
//   let width = document.body.getBoundingClientRect().width
//   let rem = window.rem
//   if (width >= 1920) {
//     rem = 192
//   }
//   let val = parseFloat(px) / rem
//   if (typeof px === 'string' && px.match(/px$/)) {
//     val += 'rem'
//   }
//   return val
// }

export function generateRandomString(length) {
  let result = ''
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
    characters =
      characters.slice(0, randomIndex) + characters.slice(randomIndex + 1)
  }

  return result
}

export const sleep = time => new Promise(resolve => setTimeout(resolve, time))

export function debounce(fn, delay) {
  let timeout
  return function() {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

export async function getSolidWorkOssPart(ctx) {
  const modal = await window.chrome.webview.hostObjects.bridge.UploadModel()
  if (!modal) return
  const data = JSON.parse(modal)
  if (!data?.ossPath) {
    //Please open or create a part file first.
    ctx.$notify({
      title: 'Error',
      message: data.message,
      type: 'error',
      customClass: 'notify-error'
    })
    return Promise.reject()
  }
  const file_name = data.fileName || ''
  return ctx
    .$getOssMessage({
      file_name: file_name.substring(file_name.lastIndexOf('\\') + 1),
      oss_path: data.ossPath,
      file_size: data.fileSize
    })
    .then(res => {
      res.data.origin_file_name = file_name
      return res
    })
    .catch(() => {
      return false
    })
}

// 判断本地时间是否处在夏令时
export function isAtDSTime () {
  let d1 = new Date(2024, 0, 1);
  let d2 = new Date(dayjs().format('YYYY,MM,DD'));
  if (d1.getTimezoneOffset() != d2.getTimezoneOffset()){
    return true;
  } else {
    return false;
  }
}
