var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_vm._m(0), _vm._v(" "), _c('el-button', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "type": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.addPart
    }
  }, [_vm._v("ADD PART TO QUOTE")]), _vm._v(" "), _c('div', {
    staticClass: "mgt20 c888 font13"
  }, [_vm._v("Learn about "), _c('a', {
    staticClass: "cursor-hand underline c444",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=b3a6f069-cb0c-4459-8804-12ebc8194387&env=na4&acct=0cc07454-3642-4f7f-a82a-020161d814bb&v=2&_ga=2.73998738.160161867.1704651358-461306255.1702382693');
      }
    }
  }, [_vm._v("RapidDirect's NDA Agreement")]), _vm._v(" or "), _c('a', {
    staticClass: "cursor-hand underline c444",
    on: {
      "click": function ($event) {
        return _vm.linkUrl('mailto:support@rapiddirect.com');
      }
    }
  }, [_vm._v("Contact us")])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "guidance c252"
  }, [_c('p', {
    staticClass: "font14 fwb title"
  }, [_vm._v("How to get started")]), _vm._v(" "), _c('p', {
    staticClass: "font13"
  }, [_vm._v("1. Open a part or create a new part in SolidWorks")]), _vm._v(" "), _c('p', {
    staticClass: "font13"
  }, [_vm._v("2. Click ADD PART TO QUOTE to upload your part")]), _vm._v(" "), _c('p', {
    staticClass: "font13"
  }, [_vm._v("3. Get an instant quote!")])]);

}]

export { render, staticRenderFns }